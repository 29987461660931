<template>
  <div id="app">
    <h1>{{ list[index] }}</h1>
    <el-button @click="start" type="primary">开始</el-button>
    <el-button @click="end">停止</el-button>
    <el-transfer @change="transferChange" :titles="['可用', '不给你用(ૢ˃ꌂ˂ૢ)']" filterable :filter-method="filterMethod" v-model="value" :data="dataList" :props="{ key: 'value', label: 'label'}"></el-transfer>
    <h1>{{ ratifyIndex>50?'朕批了':'不准(ૢ˃ꌂ˂ૢ)' }}</h1>
    <el-button @click="ratifyStart" type="primary">开始</el-button>
    <el-button @click="ratifyEnd">停止</el-button>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      index: 0,
      ratifyIndex: 0,
      value: [],
      dataList: [],
      randomList: [],
      list: [
        "豌豆",
        "冰豌豆",
        "卷心菜",
        "西瓜",
        "孢子",
        "冰西瓜",
        "火球（隐形）",
        "仙人掌刺",
        "篮球",
        "玉米粒",
        "毁灭菇",
        "黄油",
        "僵尸豌豆",
        "小阳光菇",
        "大阳光菇",
        "黑色豌豆",
        "寒冰刺",
        "魅惑箭",
        "银币",
        "金币",
        "钻石",
        "土豆雷",
        "川菜",
        "辣椒",
        "白火球",
        "土豆加农炮",
        "冰孢子",
        "小阳光",
        "豌豆僵尸的火豌豆",
        "豌豆僵尸的冰豌豆",
        "黄金豌豆",
        "大型豌豆",
        "大型冰焰豌豆",
        "大型火焰豌豆",
        "冰焰豌豆",
        "星星",
        "大星星",
      ]
    }
  },
  created() {
    this.randomList = this.list
  },
  mounted() {
    this.list.map((item, index) => {
      this.dataList.push({label: item, value: index})
    })
  },
  methods:{
    transferChange(e){
      this.randomList = []
      this.list.map((item, index) => {
        let show = true;
        e.map(i => {
          if (i == index){
            show = false
          }
        })
        if (show){
          this.randomList.push(item)
        }
      })
    },
    filterMethod(query, item) {
      return item.label.indexOf(query) > -1;
    },
    start(){
      const _this = this;
      clearInterval(_this.timer)
      _this.timer=setInterval(function(){
        _this.index=parseInt(Math.random()*_this.randomList.length)
      },10)
    },
    end(){
      clearInterval(this.timer)
    },
    ratifyStart(){
      const _this = this;
      clearInterval(_this.timesr)
      _this.timesr=setInterval(function(){
        _this.ratifyIndex=parseInt(Math.random()*100)
      },10)
    },
    ratifyEnd(){
      clearInterval(this.timesr)
    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

<style lang="postcss" scoped>
/deep/ .el-transfer-panel__item.el-checkbox .el-checkbox__label{
  text-align: left!important;
}
/deep/.el-transfer-panel .el-transfer-panel__header .el-checkbox{
  text-align: left;
}
/deep/ .el-transfer-panel__item .el-checkbox__input{
  left: 18px;
}
/deep/.el-transfer-panel{
  width: 80% !important;
  margin: 10px auto!important;
}
</style>
